<template>
  <div>
    <div class="container-wrap">
      <div class="signup-container-step">
        <div class="sign-information" :class="[activeImg===1?'active':'']">
          <img v-if="activeImg===1" class="img" src="@/assets/information.png" alt="">
          정보입력
        </div>
        <div class="sign-confirm" :class="[activeImg===2?'active':'']">
          <img class="img" v-if="activeImg!==2" src="@/assets/confirm.png" alt="">
          <img v-if="activeImg===2" class="img" src="@/assets/active_conf.png" alt="">
          가입인증
        </div>
        <div class="complete" :class="[activeImg===3?'active':'']">
          <img v-if="activeImg!==3" class="img" src="@/assets/complete.png" alt="">
          <img v-if="activeImg===3" class="img" :class=img3 src="@/assets/active_com.png" alt="">
          가입완료
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      props: ["steps"],
      name: "LpSignupProcess",
      data() {
        return {
          activeImg: 1

        }
      }
    }
</script>

<style scoped>
  .container-wrap{
    width: 100%;
  }
  .signup-container-step{
    width: 400px;
    height: 48px;
    display: flex;
    margin: 100px auto 30px auto;
    border: 1px solid #e6e6e6;
    justify-content: space-around;
    border-radius: 3px;
    line-height: 48px;
  }
  .sign-information, .sign-confirm, .complete{
    width: 133px;
    font-size: 14px;
    padding-left:10px;

  }
  .sign-confirm, .complete{
    background-color: #e6e6e6;
    color: #a0a0a0;
  }
  .img{
    width: 16px;
    height: 16px;
    background-size: cover;
    margin-bottom:-3px;
    margin-right: 5px;
  }
@media (max-width: 375px) {
  .signup-container-step{
    width: calc(100% - 25px);
  }
}

  .activeImg1{

  }
  .activeImg2{
    background-color: white;
  }
  .img3{
    background-color: white;
  }


</style>
